import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "../components/image";

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About Thomas" />
    <Image />
    <h1
      className="h2"
      style={{
        marginBottom: `1em`,
      }}
    >
      About Me
    </h1>
    <p
      style={{
        marginBottom: 0,
        fontWeight: 700,
      }}
    >
      Hi there
    </p>
    <p>
      and hi Hagerty if you are reading this. TrueCar is unresponsive and and it
      seems they don't truly look for automotive enthusiasts.
    </p>
    <p>
      I'm Thomas, a software engineer currently working at Alltrue (formerly
      CauseBox). My circumstances have changed and luck has it that I'm able to
      apply for a new job at one of the few places I'd love to work at. This
      list of places is short and TrueCar resides at the top. Cars and
      technology is a passion of mine and if you're looking for a software
      engineer with those qualities in the automotive field I hope your search
      ends here.
    </p>
    <p
      style={{
        marginBottom: 0,
        fontWeight: 700,
      }}
    >
      Why I want to work there
    </p>
    <p>
      I'm a car enthusiast and I'd like to think there are features,
      improvements, and fixes I can help with through my work at TrueCar.
      Whether it is search results, filtering, engine specs, or data processing.
      I am interested in it all. There are a lot things I know and a lot more I
      do not know. Working at TrueCar will certainly allow me to learn and at
      the same time apply my experience and knowledge to improve the product.
      Above all, I enjoy learning about trends in the market, what's popular,
      what's rising in value, and what will become future collectibles (or so
      called future classics). After reading this, if you still find yourself in
      the position of looking for someone with my background then by all means
      please reach out to <a href="mailto:thomasl@hey.com">thomasl@hey.com</a>{" "}
      and let's chat :)
    </p>
    <p>
      <Link to="/">Go back to the Thomas' resume</Link>
    </p>
    <h2
      style={{
        fontWeight: 700,
        marginBottom: `1em`,
      }}
    >
      Fun past projects
    </h2>
    <p
      style={{
        marginBottom: 0,
        fontWeight: 700,
      }}
    >
      1994 Honda Accord Engine swap (aka the golden turd)
    </p>
    <p>
      - Updated the wikipedia entry for an engine that appears to be
      non-existant.{" "}
      <a
        href="https://en.wikipedia.org/wiki/Honda_F_engine#F22B5"
        target="__blank"
      >
        See Wikipedia entry here
      </a>
    </p>
    <div
      style={{
        display: `flex`,
      }}
    >
      <div
        style={{
          width: `50%`,
          marginRight: `1em`,
          marginBottom: `1em`,
        }}
      >
        <StaticImage
          src="../images/honda-1.JPG"
          placeholder="blurred"
          layout="fullWidth"
        />
      </div>
      <div
        style={{
          width: `50%`,
        }}
      >
        <StaticImage
          src="../images/honda-2.JPG"
          placeholder="blurred"
          layout="fullWidth"
        />
      </div>
    </div>
    <p>
      Prior to the entry, it wasn't listed in Wikipedia at all. The specs were
      found from the original Honda specification manual and fuel requirements
      converted from RON to Ocatane (for those who might get confused and not
      knowing the difference). With the sparse info out there in the world, it
      is believed this block was manufactured in the US and then imported into
      Europe and only sold there under a touring variation. The engine you see
      here is new old stock in 2018 (back when the swap was done). Now this
      isn't just any engine. The original engine was the first engine for
      consumers from Honda to have VTEC. This new block from Europe didn't come
      with it but only from details in the specifications manual would anybody
      know the VTEC head fits onto the euro block. The goal of this project was
      to have the higher compression of the euro block along with VTEC for
      additional output. Sure you can say why not turbo it? The whole point is
      to have a CA smog legal car and at the same time doing the right thing.
      Many people take the easy way out but not for us on this project. We had
      to do things proper. Results? It was a success. Horsepower should be reset
      to the same or better than factory new and now it runs clean. Literally.
      Right now it runs lean and this can be confirmed because there isn't any
      black residue in the tail pipe if you wipe your fingers on it. Now only if
      there is a modern transmission to go with this... <br />
      Fun fact: The 1994 Honda Accord EX uses the same fuel injectors as the
      NSX.
    </p>
    <div
      style={{
        marginBottom: `1em`,
      }}
    >
      <StaticImage
        src="../images/honda-3.JPG"
        placeholder="blurred"
        layout="fullWidth"
      />
    </div>
    <p
      style={{
        marginBottom: 0,
        fontWeight: 700,
      }}
    >
      Home Youtube video experiments
    </p>
    <p>
      - Random car related videos. I'm sure a lot of people have thought about
      youtube before. Here are some videos I shot and edited.
    </p>
    <div
      style={{
        marginBottom: `1em`,
      }}
    >
      <div
        style={{
          position: `relative`,
          paddingBottom: `56.25%`,
          paddingTop: `0`,
          height: `0`,
        }}
      >
        <iframe
          style={{
            position: `absolute`,
            top: `0`,
            left: `0`,
            width: `100%`,
            height: `100%`,
          }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Zj6Rc0bH0qE"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
    <div
      style={{
        marginBottom: `1em`,
      }}
    >
      <div
        style={{
          position: `relative`,
          paddingBottom: `56.25%`,
          paddingTop: `0`,
          height: `0`,
        }}
      >
        <iframe
          style={{
            position: `absolute`,
            top: `0`,
            left: `0`,
            width: `100%`,
            height: `100%`,
          }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/EKuJK5O9Umc"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
    <p>
      <Link to="/">Go back to the Thomas' resume</Link>
    </p>
  </Layout>
);

export default AboutPage;

export const query = graphql`
  query {
    honda1: file(relativePath: { eq: "honda-1.JPG" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    honda2: file(relativePath: { eq: "honda-2.JPG" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    honda3: file(relativePath: { eq: "honda-3.JPG" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
