import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Image = () => (
  <StaticImage
    className="bio-avatar"
    layout="fixed"
    formats={["auto", "webp", "avif"]}
    src="../images/thomasliu-profile.jpg"
    width={150}
    height={150}
    quality={95}
    alt="Profile picture"
    imgStyle={{
      borderRadius: `50%`,
      overflow: `hidden`,
      marginBottom: `1.45rem`,
    }}
  />
);
export default Image;
